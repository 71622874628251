// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../services/auth';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar o CSS do Bootstrap
import '../styles/Login.css'; // Importar o CSS personalizado
import logo from '../assets/oficina2.png';
import logo1 from '../assets/anglo.jpg';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { token } = await signIn(username, password);
      localStorage.setItem('token', token);
      navigate('/powerbi');
    } catch (error) {
      setError('Login failed. Please check your username and password.');
    }
  };

  return (
    <div className="login-container">
      <div className="sidenav">
        <div className="login-main-text">
          <div className="logos-container">
            <img src={logo} alt="Logo" className="logo" />
            <img src={logo1} alt="Logo1" className="logo" />
          </div>
          <h2>Stakeholder <br /> <p> Dashboard </p></h2>
          <p></p>
        </div>
      </div>
      <div className="main">
        <div className="col-md-6 col-sm-12">
          <div className="login-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Login</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Login"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-black">Login</button>
              
              {error && <div className="alert alert-danger mt-2">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
