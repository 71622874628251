import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PowerBI.css';

const PowerBI = () => {
  const navigate = useNavigate();
  const url = "https://app.powerbi.com/view?r=eyJrIjoiN2VmYjYwOWMtZjM4MC00YjFjLWE4NmQtMzg5MjRlM2I1NGFiIiwidCI6IjY2ZTZiYTYxLTE3NTAtNDM2ZC05NzAyLTJlZjgxZjM0MmYwZSJ9";

  useEffect(() => {
    let timeout;

    const logoutTimeout = 10 * 60 * 1000; // 10 minutos

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleLogout, logoutTimeout);
    };

    const handleLogout = () => {
      localStorage.removeItem('token');
      navigate('/login');
    };

    window.onload = resetTimeout;
    document.onmousemove = resetTimeout;
    document.onkeypress = resetTimeout;

    // Função para prevenir o menu de contexto, seleção de texto e outras ações
    const preventDefaultActions = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const preventDevToolsShortcuts = (e) => {
      if (e.keyCode === 123 || // F12
          (e.ctrlKey && e.shiftKey && [73, 74].includes(e.keyCode)) || // Ctrl+Shift+I/J
          (e.ctrlKey && e.keyCode === 85)) { // Ctrl+U
        preventDefaultActions(e);
      }
    };

    const detectDevTools = () => {
      const threshold = 160; // Valor arbitrário para detecção
      let devtoolsOpen = false;

      const checkDevTools = () => {
        if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
          devtoolsOpen = true;
          handleLogout();
        }
      };

      window.addEventListener('resize', checkDevTools);
      return () => window.removeEventListener('resize', checkDevTools);
    };

    // Adiciona listeners para prevenir ações indesejadas
    document.addEventListener('contextmenu', preventDefaultActions); // Desativa o menu de contexto
    document.addEventListener('selectstart', preventDefaultActions); // Desativa a seleção de texto
    document.addEventListener('mousedown', preventDefaultActions); // Desativa clique com o botão direito
    document.addEventListener('mouseup', preventDefaultActions); // Desativa o botão direito do mouse
    document.addEventListener('keydown', preventDevToolsShortcuts); // Previne combinações de teclas

    // Detectar se as ferramentas de desenvolvedor estão abertas
    const stopDevToolsDetection = detectDevTools();

    // Limpar os listeners e detectar a abertura das ferramentas de desenvolvedor
    return () => {
      document.removeEventListener('contextmenu', preventDefaultActions);
      document.removeEventListener('selectstart', preventDefaultActions);
      document.removeEventListener('mousedown', preventDefaultActions);
      document.removeEventListener('mouseup', preventDefaultActions);
      document.removeEventListener('keydown', preventDevToolsShortcuts);
      stopDevToolsDetection();
    };
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="powerbi-container">
      <div className="header">
        <button onClick={handleLogout} className="btn btn-danger">Logout</button>
        <Link to="/change-password" className="btn btn-link">Alterar Senha</Link>
      </div>
      <iframe title="funcionarios" src={url} frameBorder="0" allowFullScreen></iframe>
    </div>
  );
};

export default PowerBI;
